import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "components-container" }
const _hoisted_2 = { style: {"margin-top":"30px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_draggable_select = _resolveComponent("draggable-select")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_draggable_select, {
      value: _ctx.value,
      style: {"width":"500px"},
      multiple: "",
      placeholder: "Please select",
      onChangeVal: _ctx.changeVal
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "onChangeVal"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item) => {
        return (_openBlock(), _createBlock(_component_el_tag, {
          key: item,
          style: {"margin-right":"15px"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ]))
}