
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue';
import { getArticles } from '@/apis/articles';
import DraggableList from '@/components/draggable-list/Index.vue';
export default defineComponent({
  components: {
    DraggableList
  },
  setup() {
    const state = reactive({
      list1: [],
      list2: []
    });
    onBeforeMount(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      fetchData();
    });
    const fetchData = async() => {
      const res: any = await getArticles({ /* Your params here */ });
      state.list1 = res.data.items.splice(0, 5);
      state.list2 = res.data.items;
    };
    return {
      ...toRefs(state),
      fetchData
    };
  }
});
